<template>
  <div>
    <div class="container-fluid">
      <div class="row text-center">
        <div style="margin-top: 5%; width: 100%; display: flex; justify-content: center">
          <my-alert :show="!errorMessageShow" variant="warning">
            {{ $t('loginRegister.activationProcMsg') }}
          </my-alert>
          <my-alert
            :show="dismissCountDown"
            :dismissible="true"
            variant="danger"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            <p>
              {{ this.errorMessage }}
              {{ $t('loginRegister.redirectMsg') }}
              {{ dismissCountDown }}
              {{ $t('loginRegister.second') }}
            </p>
            <v-progress-linear
              :value="(dismissCountDown / dismissSecs) * 100"
              color="warning"
              height="4"
            ></v-progress-linear>
          </my-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultHeadMixin from '@/mixins/defaultHead';
const MyAlert = () => import('@/components/utils/my-alert.vue');

export default {
  name: 'token',
  mixins: [DefaultHeadMixin],
  layout: 'default',
  components: {
    MyAlert,
  },
  data: () => ({
    errorMessage: '',
    errorMessageShow: false,
    dismissSecs: 10,
    dismissCountDown: 0,
  }),
  mounted() {
    this.accountActivation();
  },
  watch: {
    dismissCountDown() {
      if (this.dismissCountDown > 0) {
        setTimeout(() => {
          this.dismissCountDown--;
        }, 1000);
      }
    },
  },
  methods: {
    async accountActivation() {
      let response = await this.$store.dispatch(
        'global/accountActivation',
        this.$route.params.token,
      );
      if (response && response.type === 'success') {
        // JANGAN AUTO LOGIN HABIS AKTIVASI, NANTI ERROR KALAU MAU KONVERSI USER JADI AGEN
        // await this.$store.dispatch('global/updateToken', response.data.token);
        // await this.$store.dispatch('global/fetch');
        await this.$swal(
          this.$i18n.t('loginRegister.swal.activation.successTitle'),
          this.$i18n.t('loginRegister.swal.activation.successMsg'),
          'success',
        );
        this.$router.replace('/');
      } else {
        this.errorMessageShow = true;
        this.dismissCountDown = 10;
        this.errorMessage = response.error;

        setTimeout(() => {
          this.$router.replace('/login');
        }, 10000);
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<style scoped></style>
